<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">New Request</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-layout wrap justify-start>
          <v-flex xs12 pa-4>
            <v-spacer></v-spacer>
          </v-flex>
          <v-flex xs12 align-self-center>
            <v-layout wrap justify-center>
              <!-- <v-flex xs12 sm6 align-self-center text-left pa-2>
                <v-text-field
                  v-model="purchaseId"
                  label="Purchase ID"
                  :rules="[rules.required]"
                  required
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 align-self-center text-left pa-2>
                <v-text-field
                  v-model="totalAmount"
                  label="Total Price"
                  :rules="[rules.required]"
                  required
                  hide-details
                  outlined
                  type="number"
                  @wheel="$event.target.blur()"
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 align-self-center text-left pa-2>
                <v-text-field
                  v-model="discount"
                  label="Discount(INR)"
                  hide-details
                  outlined
                  type="number"
                  @wheel="$event.target.blur()"
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 align-self-center text-left pa-2>
                <v-text-field
                  v-model="discountAmount"
                  label="Discount Price"
                  hide-details
                  outlined
                  type="number"
                  @wheel="$event.target.blur()"
                  dense
                ></v-text-field>
              </v-flex> -->
              <v-flex xs12 align-self-center pa-2>
                <v-layout wrap>
                  <v-flex xs12 sm6 align-self-center text-left pa-3>
                    <span style="font-size: 18px" class="kumbhMedium">
                      Select Request type
                    </span>
                  </v-flex>
                  <v-flex>
                    <!-- <v-radio-group v-model="radios" mandatory column>
                      <template v-for="(type,t) in reqtypes" >
                      <v-radio
                      :key="t"
                        color="#005f32"
                        :label="type"
                        class="kumbhEBold"
                        :value="type"
                      ></v-radio>
                      </template> 
                    </v-radio-group> -->
                    <v-select
                    v-model="reqType"
                      :items="reqtypes"
                      dense
                      placeholder="--Request type --"
                      hide-details
                      outlined
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 pa-4>
                    <v-spacer></v-spacer>
                  </v-flex>
                  <v-flex
                    xs12
                    align-self-center
                    fill-height
                    pa-0
                    v-if="reqType == 'New Asset'"
                  >
                    <v-card tile flat>
                      <v-form v-model="addcat" ref="addcat">
                        <v-layout wrap>
                          <v-flex xs12 sm6 align-self-center text-left pa-2>
                            <v-select
                              v-model="assetId"
                              :items="assetList"
                              item-text="name"
                              item-value="_id"
                              :rules="[rules.required]"
                              hide-details
                              outlined
                              dense
                              label="Choose asset category"
                            >
                            </v-select>
                          </v-flex>

                          <v-flex xs12 sm6 align-self-center text-left pa-2>
                            <v-text-field
                              v-model="purchaseAmount"
                              label="Purchase Amount (INR)"
                              :rules="[rules.required]"
                              required
                              hide-details
                              outlined
                              type="number"
                              @wheel="$event.target.blur()"
                              dense
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs12 sm6 align-self-center text-left pa-2>
                            <v-text-field
                              v-model="quantity"
                              label="Quantity"
                              :rules="[rules.required]"
                              required
                              hide-details
                              outlined
                              type="number"
                              @wheel="$event.target.blur()"
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm6 align-self-center pa-2>
                            <v-layout wrap justify-start>
                              <v-flex xs12 sm6 md4 align-self-center px-4>
                                <v-btn
                                  color="#005f32"
                                  dark
                                  tile
                                  fab
                                  icon
                                  outlined
                                  class="rounded-xl"
                                  block
                                  small
                                  @click="addItem()"
                                >
                                  <v-icon>mdi-plus</v-icon>
                                </v-btn>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-form>
                    </v-card>
                  </v-flex>

                  <v-flex
                    xs12
                    align-self-center
                    fill-height
                    pa-0
                    v-if="radios == 2"
                  >
                    <v-card tile flat>
                      <v-form v-model="addcat2" ref="addcat2">
                        <v-layout wrap>
                          <v-flex xs12 sm6 align-self-center text-left pa-2>
                            <v-text-field
                              v-model="name"
                              label="Asset Name"
                              :rules="[rules.required]"
                              required
                              autofocus
                              hide-details
                              outlined
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm6 align-self-center text-left pa-2>
                            <v-select
                              v-model="assetCategoryId"
                              :items="categoryList"
                              item-text="name"
                              item-value="_id"
                              :rules="[rules.required]"
                              hide-details
                              outlined
                              dense
                              label="Choose asset category"
                            >
                            </v-select>
                          </v-flex>
                          <v-flex xs12 sm6 align-self-center text-left pa-2>
                            <v-select
                              v-model="assetType"
                              :items="typeList"
                              :rules="[rules.required]"
                              hide-details
                              outlined
                              dense
                              label="Choose asset type"
                            >
                            </v-select>
                          </v-flex>
                          <v-flex xs12 sm6 align-self-center text-left pa-2>
                            <v-text-field
                              v-model="purchaseAmount"
                              label="Purchase Amount (INR)"
                              :rules="[rules.required]"
                              required
                              hide-details
                              outlined
                              type="number"
                              @wheel="$event.target.blur()"
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 align-self-center text-left pa-2>
                            <v-textarea
                              v-model="description"
                              label="Description (Optional)"
                              hide-details
                              outlined
                              type="number"
                              dense
                            ></v-textarea>
                          </v-flex>

                          <v-flex xs12 sm6 align-self-center text-left pa-2>
                            <v-text-field
                              v-model="quantity"
                              label="Quantity"
                              :rules="[rules.required]"
                              required
                              outlined
                              type="number"
                              @wheel="$event.target.blur()"
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm6 align-self-center text-left pa-2>
                            <v-text-field
                              v-model="code"
                              label="Code"
                              :rules="[rules.required]"
                              required
                              outlined
                              dense
                              persistent-hint
                              hint="NB: This code could not be editable after saving."
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm6 align-self-center pa-2>
                            <v-layout wrap justify-start>
                              <v-flex
                                xs12
                                sm6
                                md4
                                align-self-center
                                text-left
                                px-4
                              >
                                <v-btn
                                  color="#005f32"
                                  dark
                                  tile
                                  outlined
                                  fab
                                  icon
                                  small
                                  class="rounded-xl"
                                  block
                                  @click="addItem()"
                                >
                                  <v-icon>mdi-plus</v-icon>
                                </v-btn>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-form>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 align-self-center pa-2>
                <v-layout wrap v-if="assets.length > 0">
                  <v-flex
                    xs12
                    sm6
                    md4
                    pa-2
                    align-self-start
                    v-for="(split, s) in assets"
                    :key="s"
                  >
                    <v-card outlined class="rounded-lg pa-3 kumbhMedium">
                      <v-layout wrap justify-end>
                        <v-flex xs12 align-self-start text-right>
                          <v-icon
                            title="Remove Item"
                            @click.prevent="assets.splice(s, 1)"
                            color="#FF1313"
                            >mdi-close</v-icon
                          >
                        </v-flex>
                      </v-layout>
                      <v-layout wrap pa-2 text-left v-if="split.code">
                        <v-flex xs6 align-self-center>
                          <span> Code </span>
                        </v-flex>
                        <v-flex xs6 align-self-center>
                          <span>
                            {{ split.code }}
                          </span>
                        </v-flex>
                      </v-layout>
                      <v-layout wrap pa-2 text-left v-if="split.name">
                        <v-flex xs6 align-self-center>
                          <span> Asset name </span>
                        </v-flex>
                        <v-flex xs6 align-self-center>
                          <span>
                            {{ split.name }}
                          </span>
                        </v-flex>
                      </v-layout>
                      <v-layout wrap pa-2 text-left v-if="split.catVal">
                        <v-flex xs6 align-self-center>
                          <span> Asset category </span>
                        </v-flex>
                        <v-flex xs6 align-self-center>
                          <span>
                            {{ split.catVal }}
                          </span>
                        </v-flex>
                      </v-layout>

                      <v-layout wrap pa-2 text-left v-if="split.assetType">
                        <v-flex xs6 align-self-center>
                          <span> Asset type </span>
                        </v-flex>
                        <v-flex xs6 align-self-center>
                          <span>
                            {{ split.assetType }}
                          </span>
                        </v-flex>
                      </v-layout>

                      <v-layout wrap pa-2 text-left v-if="split.description">
                        <v-flex xs6 align-self-center>
                          <span> Description </span>
                        </v-flex>
                        <v-flex xs6 align-self-center>
                          <span>
                            {{ split.description }}
                          </span>
                        </v-flex>
                      </v-layout>

                      <!-- <v-layout wrap pa-2 text-left v-if="split.assetId">
                        <v-flex xs6 align-self-center>
                          <span> Asset </span>
                        </v-flex>
                        <v-flex xs6 align-self-center>
                          <span>
                            {{ split.assetId }}
                          </span>
                        </v-flex>
                      </v-layout> -->
                      <v-layout wrap pa-2 text-left v-if="split.assetVal">
                        <v-flex xs6 align-self-center>
                          <span> Asset </span>
                        </v-flex>
                        <v-flex xs6 align-self-center>
                          <span>
                            {{ split.assetVal }}
                          </span>
                        </v-flex>
                      </v-layout>
                      <v-layout wrap pa-2 text-left v-if="split.purchaseAmount">
                        <v-flex xs6 align-self-center>
                          <span> Purchase Amount(INR) </span>
                        </v-flex>
                        <v-flex xs6 align-self-center>
                          <span>
                            {{ split.purchaseAmount }}
                          </span>
                        </v-flex>
                      </v-layout>
                      <v-layout wrap pa-2 text-left v-if="split.quantity">
                        <v-flex xs6 align-self-center>
                          <span> Quantity </span>
                        </v-flex>
                        <v-flex xs6 align-self-center>
                          <span>
                            {{ split.quantity }}
                          </span>
                        </v-flex>
                      </v-layout>
                      <v-layout wrap>
                        <v-flex xs12 align-self-center text-right>
                          <v-btn
                            dark
                            small
                            color="#f07305"
                            @click="editItem(split, s)"
                          >
                            <span class="kumbhMedium">Edit</span>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex xs12 sm5 md3 align-self-center pa-3>
                <v-btn
                  color="#005f32"
                  dark
                  tile
                  large
                  class="rounded-xl"
                  block
                  @click="validatePurchase()"
                >
                  Submit
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <!-- </v-card> -->
      </v-flex>
    </v-layout>

    <v-dialog
      persistent
      max-width="60%"
      hide-overlay
      transition="dialog-bottom-transition"
      v-model="editDialog"
    >
      <v-card tile v-if="editingitem">
        <v-layout wrap>
          <v-flex
            xs12
            pa-4
            text-left
            align-self-center
            style="background: #005f32 !important"
          >
            <v-layout wrap>
              <v-flex xs6 align-self-center text-left>
                <span class="kumbhBold" style="color: #ffffff; font-size: 20px"
                  >Edit Item</span
                >
              </v-flex>
              <v-flex xs6 align-self-center text-right>
                <v-btn icon color="white" text @click="editDialog = false">
                  <v-icon color="#ffffff">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 pa-4></v-flex>
          <v-flex xs12 align-self-center px-3 v-if="editingitem.radios">
            <v-layout wrap v-if="editingitem.radios == 1">
              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-select
                  v-model="editingitem.assetId"
                  :items="assetList"
                  item-text="name"
                  item-value="_id"
                  :rules="[rules.required]"
                  hide-details
                  outlined
                  dense
                  label="Choose asset category"
                >
                </v-select>
              </v-flex>
              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-text-field
                  v-model="editingitem.purchaseAmount"
                  label="Purchase Amount (INR)"
                  :rules="[rules.required]"
                  required
                  hide-details
                  outlined
                  type="number"
                  @wheel="$event.target.blur()"
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-text-field
                  v-model="editingitem.quantity"
                  label="Quantity"
                  :rules="[rules.required]"
                  required
                  hide-details
                  outlined
                  type="number"
                  @wheel="$event.target.blur()"
                  dense
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout wrap v-if="editingitem.radios == 2">
              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-text-field
                  v-model="editingitem.name"
                  label="Asset Name"
                  :rules="[rules.required]"
                  required
                  autofocus
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-select
                  v-model="editingitem.assetCategoryId"
                  :items="categoryList"
                  item-text="name"
                  item-value="_id"
                  :rules="[rules.required]"
                  hide-details
                  outlined
                  dense
                  label="Choose asset category"
                >
                </v-select>
              </v-flex>
              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-select
                  v-model="editingitem.assetType"
                  :items="typeList"
                  :rules="[rules.required]"
                  hide-details
                  outlined
                  dense
                  label="Choose asset type"
                >
                </v-select>
              </v-flex>

              <v-flex xs12 align-self-center text-left pa-2 px-4>
                <v-textarea
                  v-model="editingitem.description"
                  label="Description (Optional)"
                  hide-details
                  outlined
                  type="number"
                  dense
                ></v-textarea>
              </v-flex>

              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-text-field
                  v-model="editingitem.purchaseAmount"
                  label="Purchase Amount (INR)"
                  :rules="[rules.required]"
                  required
                  hide-details
                  outlined
                  type="number"
                  @wheel="$event.target.blur()"
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-text-field
                  v-model="editingitem.quantity"
                  label="Quantity"
                  :rules="[rules.required]"
                  required
                  hide-details
                  outlined
                  type="number"
                  @wheel="$event.target.blur()"
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                <v-text-field
                  v-model="editingitem.code"
                  label="Code"
                  :rules="[rules.required]"
                  required
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="editDialog = false"> Close </v-btn>
          <v-btn color="blue darken-1" text @click="validateEdit()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,

      addcat: false,
      addcat2: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      radios: null,
      categoryList: [],
      assetCategoryId: null,
      name: "",
      assetType: null,
      typeList: ["Consumable", "Nonconsumable","Semiconsumable"],
      assignList: ["Department", "Employee"],
      description: null,
      unitPrice: null,
      assignType: null,
      employeeId: null,
      departmentId: null,
      empList: [],
      deptList: [],
      assetList: [],
      quantity: null,
      assetId: null,
      discountAmount: 0,
      totalAmount: 0,
      purchaseId: null,
      discount: 0,
      assets: [],
      purchaseAmount: 0,
      editingitem: null,
      editDialog: false,
      ind: 0,
      code: null,
      reqtypes: null,
      reqType:null,
    };
  },
  watch: {
    radios() {
      console.log("Rad=", this.radios);
      this.clearOff();
    },
    totalAmount() {
      this.discountAmount =
        parseFloat(this.totalAmount) - parseFloat(this.discount);
    },
    discount() {
      this.discountAmount =
        parseFloat(this.totalAmount) - parseFloat(this.discount);
    },
  },
  beforeMount() {
    this.getReqTypes();
    this.getCategories();
    this.getDept();
    this.getEmp();
    this.getAssets();
  },

  methods: {
    getReqTypes() {
      this.appLoading = true;
      axios({
        url: "/asset/request/type",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.reqtypes = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    addItem() {
      var anItem = {};
      if (this.radios == 1) {
        if (!this.assetId) {
          this.msg = "Please choose an asset";
          this.showsnackbar = true;
          return;
        } else if (!this.purchaseAmount) {
          this.msg = "Please provide purchase amount";
          this.showsnackbar = true;
          return;
        } else if (!this.quantity) {
          this.msg = "Please provide quantity";
          this.showsnackbar = true;
          return;
        } else {
          var arr = null;
          anItem.radios = 1;
          anItem.assetId = this.assetId;
          arr = this.assetList.filter((x) => x._id == anItem.assetId);
          anItem.assetVal = arr[0].name;
          anItem.quantity = this.quantity;
          anItem.purchaseAmount = this.purchaseAmount;

          this.assets.push(anItem);
          console.log("Asset==", this.assets);
        }
      } else if (this.radios == 2) {
        if (!this.name) {
          this.msg = "Please provide asset name";
          this.showsnackbar = true;
          return;
        } else if (!this.assetCategoryId) {
          this.msg = "Please provide asset category Id";
          this.showsnackbar = true;
          return;
        } else if (!this.assetType) {
          this.msg = "Please provide asset type";
          this.showsnackbar = true;
          return;
        } else if (!this.purchaseAmount) {
          this.msg = "Please provide purchase amount";
          this.showsnackbar = true;
          return;
        } else if (!this.quantity) {
          this.msg = "Please provide quantity";
          this.showsnackbar = true;
          return;
        } else if (!this.code) {
          this.msg = "Please provide code";
          this.showsnackbar = true;
          return;
        } else {
          anItem = {};
          anItem.radios = 2;
          anItem.name = this.name;
          anItem.assetCategoryId = this.assetCategoryId;

          var crr = this.categoryList.filter(
            (x) => x._id == anItem.assetCategoryId
          );
          anItem.catVal = crr[0].name;
          anItem.assetType = this.assetType;
          anItem.description = this.description;

          anItem.quantity = this.quantity;
          anItem.code = this.code;
          anItem.purchaseAmount = this.purchaseAmount;

          this.assets.push(anItem);
          console.log("Asset==", this.assets);
        }
      }
    },

    editItem(item, i) {
      console.log("It==", item);
      this.editingitem = {};
      this.ind = i;
      this.editingitem.radios = item.radios;
      console.log("df");
      if (item.radios == 1) {
        this.editingitem.assetId = item.assetId;
        this.editingitem.purchaseAmount = item.purchaseAmount;
        this.editingitem.quantity = item.quantity;
        this.editDialog = true;
      } else if (item.radios == 2) {
        this.editingitem.name = item.name;
        this.editingitem.assetCategoryId = item.assetCategoryId;
        this.editingitem.assetType = item.assetType;
        if (item.description) this.editingitem.description = item.description;

        this.editingitem.purchaseAmount = item.purchaseAmount;
        this.editingitem.quantity = item.quantity;
        this.editingitem.code = item.code;
        this.editDialog = true;
      }
    },

    validateEdit() {
      if (this.editingitem.radios == 1) {
        if (!this.editingitem.assetId) {
          this.msg = "Please choose an asset";
          this.showsnackbar = true;
          return;
        } else if (!this.editingitem.purchaseAmount) {
          this.msg = "Please provide purchase amount";
          this.showsnackbar = true;
          return;
        } else if (!this.editingitem.quantity) {
          this.msg = "Please provide quantity";
          this.showsnackbar = true;
          return;
        } else {
          this.assets[this.ind].assetId = this.editingitem.assetId;

          var arrr = this.assetList.filter(
            (x) => x._id == this.editingitem.assetId
          );
          this.assets[this.ind].assetVal = arrr[0].name;

          this.assets[this.ind].quantity = this.editingitem.quantity;
          this.assets[this.ind].purchaseAmount =
            this.editingitem.purchaseAmount;

          // this.assets.push(anItem);
          console.log("All upast==", this.assets);
        }
      } else if (this.editingitem.radios == 2) {
        if (!this.editingitem.name) {
          this.msg = "Please provide asset name";
          this.showsnackbar = true;
          return;
        } else if (!this.editingitem.assetCategoryId) {
          this.msg = "Please provide asset category Id";
          this.showsnackbar = true;
          return;
        } else if (!this.editingitem.assetType) {
          this.msg = "Please provide asset type";
          this.showsnackbar = true;
          return;
        } else if (!this.editingitem.purchaseAmount) {
          this.msg = "Please provide purchase amount";
          this.showsnackbar = true;
          return;
        } else if (!this.editingitem.quantity) {
          this.msg = "Please provide quantity";
          this.showsnackbar = true;
          return;
        } else if (!this.editingitem.code) {
          this.msg = "Please provide code";
          this.showsnackbar = true;
          return;
        } else {
          this.assets[this.ind].name = this.editingitem.name;
          this.assets[this.ind].assetCategoryId =
            this.editingitem.assetCategoryId;

          var crrr = this.categoryList.filter(
            (x) => x._id == this.editingitem.assetCategoryId
          );
          this.assets[this.ind].catVal = crrr[0].name;
          this.assets[this.ind].assetType = this.editingitem.assetType;
          this.assets[this.ind].description = this.editingitem.description;

          this.assets[this.ind].quantity = this.editingitem.quantity;
          this.assets[this.ind].code = this.editingitem.code;
          this.assets[this.ind].purchaseAmount =
            this.editingitem.purchaseAmount;

          console.log("ALl Up Asset==", this.assets);
        }
      }
      this.editDialog = false;
      this.msg = "Item updated!";
      this.showsnackbar = true;
    },
    getAssets() {
      this.appLoading = true;
      axios({
        url: "/assets/list",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.assetList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getCategories() {
      this.appLoading = true;
      axios({
        url: "/assetCategory/list",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.categoryList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getDept() {
      this.appLoading = true;
      axios({
        url: "/department/getalllist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.deptList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getEmp() {
      this.appLoading = true;
      axios({
        url: "/employees/getalllist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.empList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    validatePurchase() {
      if (!this.purchaseId) {
        this.msg = "Please provide purchase Id";
        this.showsnackbar = true;
        return;
      } else if (!this.totalAmount) {
        this.msg = "Please provide total amount";
        this.showsnackbar = true;
        return;
      } else if (!this.assets.length > 0) {
        this.msg = "Please add at least one item!";
        this.showsnackbar = true;
        return;
      } else if (this.totalAmount) {
        var tot = 0;
        for (let i = 0; i < this.assets.length; i++) {
          tot = JSON.parse(tot) + JSON.parse(this.assets[i].purchaseAmount);
        }
        console.log(
          "Total cl=",
          tot,
          " & ",
          this.totalAmount,
          " & dis=",
          this.discountAmount
        );

        if (tot !== this.discountAmount) {
          this.msg =
            "Mismatch!.Total discounted price and bill items amounts are different.";
          this.showsnackbar = true;
          return;
        } else {
          this.addPurchase();
        }
      } else {
        this.addPurchase();
      }
    },
    addPurchase() {
      // var data = {};
      // data["name"] = this.name;
      this.appLoading = true;
      axios({
        url: "/add/asset/purchase",
        method: "POST",
        data: {
          purchaseId: this.purchaseId,
          totalAmount: this.totalAmount,
          discount: this.discount,
          assets: this.assets,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.name = null;
            this.dialog = false;

            this.$router.push("/Assets/Inventory");
            this.clearOff();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    clearOff() {
      if (this.radios == 1) {
        this.name = "";
        this.assetType = null;
        this.description = null;
        this.assetCategoryId = null;
      }
      if (this.radios == 2) {
        this.assetId = null;
      }
    },

    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/assetCategory/remove",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
